/* App.css */
body {
  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  justify-content: space-between;
  background-color: rgb(53, 53, 166);
  padding: 5px 25px;
}
/* Estilos para asegurarse de que el video se ajuste correctamente */
video {
  width: 100%;
  max-width: 500px;
  max-height: 300px;
  object-fit: cover;
}
.divselectpersona{
  margin-bottom: 0 !important;
}
.divselectfuncion{
  margin-bottom: 0 !important;
}
/* Estilos específicos para iOS */
@media only screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
  video {
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 300px;
    object-fit: cover;
  }
}
.horaactual {
  text-align: right;
  margin-left: auto;
  margin-right: 20px;
  font-size: 18px;
  color: rgb(26, 26, 161);
  font-weight: 500;
  margin-right: 50px;
}

.headertabla {
  padding: 5px 50px;
  display: flex;
  justify-content: space-around;
  height: 100px;
  margin-top: 25px;
  width: 80%;
  margin: auto;
}

.sloganerlan {
  width: 20%;
  height: 100px;
}

a {
  text-decoration: none;
}

a:hover {
  color: white;
}

.atras {
  font-size: 18px;
  color: #fff;
  margin: 10px 30px;
}

.salir {
  font-size: 18px;
  color: #fff;
  margin: 10px 30px;
}

.control {
  font-size: 18px;
  font-weight: bold;
  color: rgb(26, 26, 161);
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
}

.tituloregistro {
  font-size: 25px;
  font-weight: bold;
  color: rgb(37, 134, 13);
  margin-bottom: 30px;
  text-align: center;
  margin-top: 20px;
}

.app-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.datos-usuario {
  text-align: right;
  margin-left: auto;
  margin-right: 50px;
  text-transform: none;
  font-size: 20px;
  color: rgb(26, 26, 161);
  font-weight: bold;
}

.datos-usuarioizq {
  text-align: left;
  margin-left: auto;
  margin-right: 50px;
  text-transform: none;
  font-size: 20px;
  color: rgb(26, 26, 161);
  font-weight: bold;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mapita {
  position: inherit;
}

label {
  margin-right: 10px;
  font-size: 18px;
}

.error-message {
  margin-bottom: 15px;
  text-align: center;
  background-color: rgb(227, 227, 94);
  padding: 10px;
  border-radius: 5px;
  width: 20%;
}

.botonsalida {
  color: white;
  font-size: 20px;
}

.obtener-datos-btn {
  background-color: rgb(22, 73, 200);
  color: #fff;
  font-weight: bold;
  padding: 8px 50px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 3px 5px #4f7093;
  margin-top: 20px;

}

.obtener-datos-btnqr {
  background-color: rgb(22, 73, 200);
  color: #fff;
  font-weight: bold;
  padding: 8px 50px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 3px 5px #4f7093;
  margin-top: 20px;
  margin-left: 21%;
}

.obtener-datos-btn:active {
  background-color: rgb(165, 39, 39);
  box-shadow: 5px 3px 5px #49aa7d;
}

.titulodatos {
  color: white;
  padding: 10px;
  margin-left: 30px;
  font-size: 25px;
  text-align: center;
}

.xcerrar {
  background-color: rgb(222, 45, 45);
  color: white;
  width: 6%;
  padding: 6px;
  font-weight: bold;
}

.funcionseleccionada {
  background-color: #007bff;
  text-align: center;
  padding: 10px;
  color: white;
  width: 100%;
  font-weight: bold;
}

.funcionnoseleccionada {
  background-color: red;
  text-align: center;
  padding: 10px;
  color: white;
  width: 100%;
  font-weight: bold;
}

.custom-modal .botoncerrar {
  background-color: rgb(194, 15, 15);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;

}

.custom-modal .botonentrada {
  background-color: rgb(61, 180, 34);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;

}

.custom-modal .botonentrada:hover {
  background-color: rgb(61, 180, 34);
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.custom-modal .botonsalida {
  background-color: rgb(53, 53, 166);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin: 5px;
}

.custom-modal .botonsalida:hover {
  background-color: rgb(53, 53, 166);
  padding: 3px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.custom-modal button .registrarplaca {
  background-color: rgb(107, 194, 15);
  width: 40%;
  padding: 3px;
  color: white;
  font-size: 20px;
  box-shadow: 0 0 5px 2px rgb(107, 194, 15);
  cursor: pointer;
}

.custom-modal button .registrarplaca:hover {
  background-color: rgb(170, 213, 49);
  padding: 3px;
  color: white;
  font-size: 20px;
  box-shadow: 0 0 5px 2px rgb(170, 213, 49);
  cursor: pointer;
}
.divweb{
  min-width: 100%;

}
.fechayhora {
  font-size: 18px;
}

.datos {
  padding: 15px;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.card:hover {
  box-shadow: 0 8px 50px #23232333;
 }
@media screen and (max-width:480px) {
  .global {
    margin-top: 30%;
    width: 100%;
  }
  #root{
    width: 100%;
  }
  body {
    align-items: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
    width: 100%;

  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 5px;
  }

  .horaactual {
    font-size: 10px !important;
    margin-right: 20px;
    margin-top: 5px;
    color: #707171 !important;
  }

  .logoerlan {
    width: 40% !important;
    height: 50px !important;
    object-fit: cover;
  }

  .atras {
    font-size: 12px !important;
    color: #fff;
    margin: 10px 10px !important;
  }

  .salir {
    font-size: 12px !important;
    color: #fff;
    margin: 10px 10px !important;
  }

  .tituloregistro {
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .inputboton {
    display: flex;
    padding: 4px;
    font-size: 10px;
    width: 80% !important;
  }

  .botonesabajo {
    display: flex;
    padding: 4px;
    font-size: 10px;
    width: 80% !important;
    margin-top: 0 !important;
  }
  #placaInput {
    margin: 0 !important;
    border-radius: 2px !important;
    max-width: 100% !important;
    text-align: center;
    border: 1px solid #0091ff;
    font-size: 13px !important;
    margin-top: 10px !important;
    white-space: pre-wrap; /* Permite el ajuste del texto a múltiples líneas */
    overflow: hidden; /* Oculta cualquier desbordamiento */
    resize: none; /* Desactiva el redimensionamiento del textarea */
    height: auto; /* Ajusta la altura automáticamente al contenido */
    padding: 5px; /* Añade padding para mejorar la legibilidad */
  }
  #placaInput2 {
    margin: 0 !important;
    border-radius: 2px !important;
    width: 100% !important;
    text-align: center;
    border: 1px solid #0091ff;
    font-size: 13px !important;
    height: 30px;
    margin-top: 10px !important;
  }
  .botonregistrar {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    height: 40px;

  }

  .botonlistarnombres {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    height: 40px;

  }

  .verregistrosgeneral {
    width: 50% !important;
    font-size: 8px;
    border-radius: 2px !important;
    margin: 0 !important;

  }

  .dropdown {
    width: 50% !important;
    font-size: 13px !important;
    border-radius: 2px !important;
  }

  .dropdown button {
    width: 100% !important;
    font-size: 12px !important;
    border-radius: 2px !important;
    height: 40px;

  }

  .registrosusuario {
    max-width: 90%;
  }

  .btnmisregistros {
    max-width: 90%;
  }

  .cargarcamara {
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;
    height: 30px;

  }
  .divselectpersona{
    width: 90% !important;
    margin-top: 0 !important;
  }
  .divdatosdniapellidos{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .divselectfuncion{
    width: 90% !important;
  }
  .selectpersona{
    font-size: 12px;
  }
  .selectfuncion{
    font-size: 12px;
  }
  .botoncambiarcamara {
    display: initial !important;
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;
    height: 50px;
  }

  .botonlimpiarfoto {
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;
    height: 30px;

  }

  .divbotonesbajocamara {
    width: 95% !important;
    margin-top: 10px !important;
  }

  .botontomarfoto {
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;
    height: 40px;
    margin-bottom: 10px;
  }

  .botonsubirfoto {
    font-size: 12px !important;
    width: 100% !important;
    border-radius: 2px;
    height: 40px;
    margin-bottom: 10px;
  }
  .alertpasos{
    width: 90% !important;
    margin-bottom: 0 !important;
  }
  .divweb{
    margin-top: 0px !important;
  }
  .titulomodalregistro {
    font-size: 14px;
    text-align: center;
  }

  .radiobutonstr label {
    font-size: 12px !important;
  }

  .alertafuncionselec {
    font-size: 10px !important;
  }

  .cardprincipal {
    width: 100% !important;
  }

  .inputbuscador {
    font-size: 10px !important;
    max-width: 100% !important;
  }

  .camaraconcainer {
    width: 90% !important;
    max-width: 90% !important;
  }

  .botonbuscador {
    font-size: 10px !important;
  }

  nav {
    padding: 5px 0;
  }

  .control {
    padding: 10px;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }

  .botonps {
    font-size: 9px;
  }

  .gestionarpagoimg {
    height: 50px !important;
  }

  #verasistenciabtn button {
    font-size: 9px !important;
  }

  .datos-usuario {
    text-align: right;
    margin-left: auto;
    margin-right: 20px;
    font-size: 10px !important;
    color: rgb(26, 26, 161);
    font-weight: bold;
  }

  .datos-usuarioizq {
    font-size: 9px !important;
    width: 100%;
    color: rgb(68, 68, 69);
    font-weight: bold;
    margin: 10px 0 0 10px;
    margin-top: 5px !important;

  }

  .divcodigos {
    display: flex;
    padding: 5px;
  }

  .nombresapellidosmodal {
    font-size: 12px !important;
  }

  .funcionmodal {
    font-size: 12px !important;
  }

  .tiporegistromodal {
    font-size: 12px !important;
  }

  .tiporegistromodal select {
    width: 70% !important;
  }

  label {
    margin-right: 0;
    font-size: 13px;
  }

  .obtener-datos-btn {
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    width: 70%;
    margin-top: 10px;
    padding: 8px 20px;
  }

  .titulodatos {
    padding: 10px;
    margin-left: 0;
    font-size: 15px;
  }

  .custom-modal .botonentrada {
    font-size: 12px;
  }

  .custom-modal .botonsalida {
    font-size: 12px;
  }

  .custom-modal .botoncerrar {
    font-size: 12px;
  }

  .fechayhora {
    font-size: 14px;
  }
  .CambiarPass-Container{
    width: 100% !important;
    padding: 0 !important;
    height: 500px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 50% !important;
  }
  .datos {
    display: flex;
    flex-direction: column;
  }

  .botonesregistros button {
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .botonesmapas {
    display: flex;
    flex-direction: column;
  }

  .botonmapageneral {
    width: 100%;
  }
  .botonesCambiarpass button{
    font-size: 12px
  }
  .botonmapageneral button {
    width: 95%;
    font-size: 12px;
  }

  .botonessuccess {
    justify-content: center;
  }

  .botonessuccess button {
    font-size: 12px;
  }

  .botoncambiocontra {
    font-size: 10px;
    width: 5px;
  }

  .error-message {
    width: 90%;
  }

  .xcerrar {
    width: 15%;
    padding: 5px;
    font-size: 12px;
  }

  .selectintervalo {
    font-size: 10px !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .cambiarintervalo {
    gap: 10px;
  }
}

.botonesmodal {
  display: flex;
  justify-content: space-around;
}

.seleccimagen {
  background-color: rgb(42, 203, 42);
  border: none;
  width: 170px;
}

.textplaca {
  font-size: 20px;
}